<template>
  <div>
    <a-modal
      v-model="visible"
      :title="ppId > 0 ? '修改申请' : '新增申请'"
      :confirmLoading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="851"
      centered
      destroyOnClose
    >
      <a-form-model
        ref="ruleForm"
        :rules="rules"
        :model="pageData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="外出单位" prop="goUnit">
              <a-input v-model="pageData.goUnit" class="searchIpt" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="外出日期" prop="goStartDate">
              <a-date-picker
                v-model="pageData.goStartDate"
                format="YYYY-MM-DD"
                class="searchIpt"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="带队负责人" prop="goResponsibleUser">
              <a-input v-model="pageData.goResponsibleUser" class="searchIpt" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="返回日期" prop="goEndDate">
              <a-date-picker
                v-model="pageData.goEndDate"
                format="YYYY-MM-DD"
                class="searchIpt"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="职务" prop="goResponsiblePost">
              <a-input v-model="pageData.goResponsiblePost" class="searchIpt" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="带队教师名单" prop="goTeacherNames">
              <a-input v-model="pageData.goTeacherNames" class="searchIpt" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="外出学生数" prop="goStuNum">
              <a-input v-model="pageData.goStuNum" class="searchIpt" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="带队教师人数" prop="goTeacherNum">
              <a-input v-model="pageData.goTeacherNum" class="searchIpt" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="外出目的地" prop="goEndArea">
              <a-input v-model="pageData.goEndArea" class="searchIpt" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="经费来源" prop="goExpensesSource">
              <a-input v-model="pageData.goExpensesSource" class="searchIpt" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="外出类型" prop="goType">
              <a-select v-model="pageData.goType" class="searchIpt" allowClear>
                <a-select-option :value="1">研学</a-select-option>
                <a-select-option :value="2">竞赛</a-select-option>
                <a-select-option :value="3">综合实践活动</a-select-option>
                <a-select-option :value="4">其他</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="出行方式" prop="goTransport">
              <a-input v-model="pageData.goTransport" class="searchIpt" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="途径路线"
              prop="goRoute"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                v-model="pageData.goRoute"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="外出事由"
              prop="goReason"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                v-model="pageData.goReason"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="附件照片"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 18 }"
            >
              <div class="picList">
                <UploadImage
                  @setImage="setImage"
                  :maxNum="3"
                  :imgList="pageData.goInfoPhotos"
                />
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="附件"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 18 }"
            >
              <UploadFile @setFile="setFile" :fileList="pageData.goInfoFile" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { getCurrentUnit, getEntityById, add, edit } from "@/api/peopleOut";
import UploadImage from "@/component/uploadImage/uploadImage";
import UploadFile from "@/component/uploadFile/uploadFile";
import moment from "moment";
export default {
  name: "peopleApply",
  data() {
    const validatorNumber = (rule, value, callback)=>{
      if (!value) {
        callback(new Error('请输入人数!'))
      } else {
        if (!/^[0-9]*$/.test(value)) {
          callback(new Error('格式不正确!'))
        } else {
          callback()
        }
      }
    }
    return {
      confirmLoading: false,
      visible: false,
      pageData: {
        goUnit: "",
        goStartDate: "",
        goResponsibleUser: "",
        goResponsiblePost: "",
        goStuNum: "",
        goTeacherNum: "",
        goEndArea: "",
        goExpensesSource: "",
        goType: "",
        goTransport: "",
        goRoute: "",
        goReason: "",
        goInfoPhotos: [],
        goInfoFile: [],
        goEndDate: "",
        goTeacherNames: "",
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      rules:{
        goStartDate:[{required:true,message:'该选项必填！'}],
        goResponsibleUser:[{required:true,message:'该选项必填！'}],
        goResponsiblePost:[{required:true,message:'该选项必填！'}],
        goStuNum:[{required:true,validator:validatorNumber}],
        goEndArea:[{required:true,message:'该选项必填！'}],
        goExpensesSource:[{required:true,message:'该选项必填！'}],
        goType:[{required:true,message:'该选项必填！'}],
        goTransport:[{required:true,message:'该选项必填！'}],
        goRoute:[{required:true,message:'该选项必填！'}],
        goReason:[{required:true,message:'该选项必填！'}],
        goStartDate:[{required:true,message:'该选项必填！'}],
        goTeacherNum:[{required:true,validator:validatorNumber}],
        goEndDate:[{required:true,message:'该选项必填！'}],
        goTeacherNames:[{required:true,message:'该选项必填！'}],
      }
    };
  },
  props: {
    ppId: {
      type: Number,
      default: -1,
    },
  },
  components: {
    UploadImage,
    UploadFile,
  },
  methods: {
    moment,
    setImage(val) {
      this.pageData.goInfoPhotos = val;
    },
    setFile(val) {
      console.log(val);
      this.pageData.goInfoFile = val;
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid)=>{
        if (valid) {
          let files = this.pageData.goInfoFile;
          let data = {
            ...this.pageData,
            goStartDate: moment(this.pageData.goStartDate).format("YYYY-MM-DD"),
            goEndDate: moment(this.pageData.goEndDate).format("YYYY-MM-DD"),
            goInfoPhotos: this.pageData.goInfoPhotos.join(","),
            goInfoFile: this.pageData.goInfoFile
              .map((item) => {
                return item.url;
              })
              .join(","),
            goInfoFileNames: files
              .map((item) => {
                return item.name;
              })
              .join(","),
          };
          this.confirmLoading = true;

          if (this.ppId > 0) {
            data.id = this.ppId;
            edit(data).then((res) => {
              if (res.data.code == 200) {
                this.openNotification("success", res.data.msg);
                this.visible = false;
                this.$emit("refreshTable", {});
              } else {
                this.confirmLoading = false;

                this.openNotification("error", res.data.msg);
              }
            });
          } else {
            add(data).then((res) => {
              if (res.data.code == 200) {
                this.openNotification("success", res.data.msg);
                this.visible = false;
                this.$emit("refreshTable", {});
              } else {
                this.confirmLoading = false;

                this.openNotification("error", res.data.msg);
              }
            });
          }
        }else{
          this.confirmLoading = false;
          return false;
        };
      });
      
    },
    handleCancel() {
      this.visible = false;
      this.confirmLoading = false;
    },
    showModal() {
      this.getCurrentUnit();
      if (this.ppId > 0) {
        this.getInfo();
      } else {
        this.pageData = {
          goUnit: "",
          goStartDate: "",
          goEndDate: "",
          goResponsibleUser: "",
          goResponsiblePost: "",
          goStuNum: "",
          goTeacherNum: "",
          goTeacherNames: "",
          goEndArea: "",
          goExpensesSource: "",
          goType: "",
          goTransport: "",
          goRoute: "",
          goReason: "",
          goInfoPhotos: [],
          goInfoFile: [],
        };
      }
      this.visible = true;
      this.confirmLoading = false;
    },
    getCurrentUnit() {
      let data = {};
      getCurrentUnit(data).then((res) => {
        this.pageData.goUnit = res.data.data;
      });
    },
    getInfo() {
      let data = {
        id: this.ppId,
      };
      getEntityById(data).then((res) => {
        this.pageData = {
          goUnit: this.pageData.goUnit,
          goStartDate: res.data.data.goStartDate,
          goResponsibleUser: res.data.data.goResponsibleUser,
          goResponsiblePost: res.data.data.goResponsiblePost,
          goStuNum: res.data.data.goStuNum,
          goTeacherNum: res.data.data.goTeacherNum,
          goEndArea: res.data.data.goEndArea,
          goExpensesSource: res.data.data.goExpensesSource,
          goType: res.data.data.goType,
          goTransport: res.data.data.goTransport,
          goRoute: res.data.data.goRoute,
          goReason: res.data.data.goReason,
          goInfoPhotos: res.data.data.photoUrls ? res.data.data.photoUrls : [],
          goInfoFile: res.data.data.fileUrls
            ? res.data.data.fileUrls.map((item, index) => {
                return {
                  url: item,
                  name: res.data.data.fileNames[index],
                };
              })
            : [],
          goEndDate: res.data.data.goEndDate,
          goTeacherNames: res.data.data.goTeacherNames,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.searchIpt {
  width: 200px;
}
.picList {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    color: #101010;
    cursor: pointer;
  }
  .addFile {
    color: #3985f9;
    font-size: 29px;
    cursor: pointer;
  }
  img {
    margin-right: 8px;
    width: 80px;
    height: 80px;
  }
}
</style>
