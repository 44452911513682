<template>
  <div>
    <!-- 详情 -->
    <a-drawer
      width="519"
      title="详情"
      placement="right"
      :closable="true"
      :visible="drawervisible"
      @close="onClose"
      destroyOnClose
      :wrapStyle="{ overflow: 'hidden' }"
      :bodyStyle="{
        padding: 0,
        background: '#F2F4F6',
        height: 'calc(100% - 55px)',
      }"
    >
      <div id="print">
        <div class="drawHead">
          <div class="drawHead_top">
            <div class="drawHead_number">编号：{{ itemInfo.number }}</div>
            <div class="printBtn" v-print="'#print'">
              <a-icon type="printer" class="printImg" />
              <div class="print_text">打印</div>
            </div>
          </div>
          <div class="drawHead_title">{{ itemInfo.name }}</div>
          <div class="drawHead_submitPerson">
            提交人：{{ itemInfo.submitPerson }}
          </div>
        </div>
        <div class="drawContent1">
          <div class="drawContent_info">
            <a-row class="marginBottom">
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">外出类型：</div>
                  <div class="item_right">{{ itemInfo.outType }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">状态：</div>
                  <div class="item_right">
                    {{ itemInfo.status }}
                  </div>
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">外出单位：</div>
                  <div class="item_right">{{ itemInfo.outUnit }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">外出日期：</div>
                  <div class="item_right">{{ itemInfo.outTime }}</div>
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">带队负责人：</div>
                  <div class="item_right">{{ itemInfo.leader }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">返回日期：</div>
                  <div class="item_right">{{ itemInfo.returnTime }}</div>
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">职务：</div>
                  <div class="item_right">{{ itemInfo.job }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">带队教师名单：</div>
                  <div class="item_right">{{ itemInfo.outTeachersNames }}</div>
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">外出学生数：</div>
                  <div class="item_right">{{ itemInfo.outStudents }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">带队教师人数：</div>
                  <div class="item_right">{{ itemInfo.outTeachers }}</div>
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">外出目的地：</div>
                  <div class="item_right">{{ itemInfo.outAddress }}</div>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="drawContent_info_item">
                  <div class="item_left">经费来源：</div>
                  <div class="item_right">{{ itemInfo.moneySource }}</div>
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="24">
                <div class="drawContent_item_title">出行方式：</div>
                <div class="drawContent_item_text">{{ itemInfo.outWay }}</div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="24">
                <div class="drawContent_item_title">途径路线：</div>
                <div class="drawContent_item_text">{{ itemInfo.route }}</div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="24">
                <div class="drawContent_item_title">外出事由：</div>
                <div class="drawContent_item_text">
                  {{ itemInfo.outReason }}
                </div>
              </a-col>
            </a-row>
            <a-row class="marginBottom">
              <a-col :span="24">
                <div class="drawContent_item_title">附件照片：</div>
                <div class="drawContent_item_pic">
                  <img
                    :src="item"
                    v-for="(item, index) in itemInfo.picture"
                    :key="index"
                  />
                </div>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <div class="drawContent_item_title">附件：</div>
                <div class="drawContent_item_info">
                  <div
                    class="drawContent_item_info_item"
                    v-for="(item, index) in itemInfo.fileNames"
                    :key="index"
                  >
                    <a-icon type="file-excel" class="file_item_icon" />
                    <span @click="download(index)">{{ item }}</span>
                    <span
                      @click="showOpen(index)"
                      style="color: #0279f0"
                      v-if="
                        item.indexOf('.docx') != -1 ||
                        item.indexOf('.pdf') != -1
                      "
                      >预览</span
                    >
                  </div>
                  <ShowWorld
                    ref="showWorld"
                    :url="worldUrl"
                    :worldTitle="wordTitle"
                    @fatherMethod="fatherMethod"
                  />
                  <show-pdf
                    :url="worldUrl"
                    :pdfTitle="wordTitle"
                    ref="showPdf"
                  />
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="drawContent_procedure">
            <div class="drawContent_procedure_title">流程</div>
            <div class="stepDiv">
              <a-steps direction="vertical">
                <a-step
                  status="finish"
                  v-for="(item, index) in itemInfo.processList"
                  :key="index"
                >
                  <template slot="title">
                    <div class="stepText">
                      <div class="stepTitle">
                        <div class="stepTitleLeft">{{ item.op }}</div>
                        <div class="stepTitleRight" v-if="item.time !== '未知'">
                          {{ item.time }}
                        </div>
                      </div>
                      <div class="stepName">{{ item.name }}</div>
                    </div>
                  </template>
                  <img
                    :src="item.avatar ? item.avatar : ''"
                    slot="icon"
                    class="stepIcon"
                  />
                </a-step>
              </a-steps>
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getEntityById } from "@/api/peopleOut";
import ShowWorld from "@/component/showWorld/showWorld";
import ShowPdf from "@/component/showPdf/showPdf";
export default {
  name: "peopleApply",
  components: { ShowWorld, ShowPdf },
  data() {
    return {
      drawervisible: false,
      itemInfo: {
        number: "",
        name: "",
        submitPerson: "",
        outType: "",
        status: "",
        outUnit: "",
        outTime: "",
        leader: "",
        job: "",
        outStudents: 0,
        outTeachers: 0,
        outAddress: "",
        moneySource: "",
        outWay: "",
        route: "",
        outReason: "",
        picture: [],
        fileUrls: [],
        processList: [],
        fileNames: [],
        returnTime: "",
        outTeachersNames: "",
      },
      worldUrl: "",
      wordTitle: "",
    };
  },
  props: {
    ppId: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    windPrint() {
      // window.print();
      let newstr = document.getElementById("printInfo").innerHTML;
      let oldstr = document.body.innerHTML;
      document.body.innerHTML = newstr;
      window.print();
      document.body.innerHTML = oldstr;
      window.location.reload();
      return false;
      // this.$emit('windowPrint', {})
    },
    doPrint() {
      var iframe = document.getElementById("print-iframe");
      if (!iframe) {
        var el = document.getElementById("printInfo");
        iframe = document.createElement("IFRAME");
        var doc = null;
        iframe.setAttribute("id", "print-iframe");
        iframe.setAttribute(
          "style",
          "position:absolute;width:0px;height:0px;left:-500px;top:-500px;"
        );
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        //这里可以自定义样式
        doc.write(
          '<style media="print">@page {size: auto;margin: 0mm;}</style>'
        ); //解决出现页眉页脚和路径的问题
        doc.write("<div>" + el.innerHTML + "</div>");
        doc.close();
        iframe.contentWindow.focus();
      }
      setTimeout(function () {
        iframe.contentWindow.print();
      }, 50); //解决第一次样式不生效的问题
      if (navigator.userAgent.indexOf("MSIE") > 0) {
        document.body.removeChild(iframe);
      }
    },
    showDrawer() {
      this.getInfo();
      this.drawervisible = true;
    },
    onClose() {
      this.drawervisible = false;
    },
    download(idx) {
      window.open(this.itemInfo.fileUrls[idx]);
    },
    showOpen(idx) {
      console.log(this.itemInfo.fileUrls[idx]);
      this.worldUrl = this.itemInfo.fileUrls[idx];
      this.wordTitle = this.itemInfo.fileNames[idx];
      setTimeout(() => {
        if (this.wordTitle.indexOf(".docx") != -1) {
          this.$refs.showWorld.showModal();
        } else if (this.wordTitle.indexOf(".pdf") != -1) {
          this.$refs.showPdf.showModal();
        } else {
          return;
        }
      }, 0);
    },
    getInfo() {
      let data = {
        id: this.ppId,
      };
      getEntityById(data).then((res) => {
        this.itemInfo = {
          number: res.data.data.goCode,
          name: res.data.data.schoolName + "提交的外出申请",
          submitPerson: res.data.data.createUserName,
          outType: res.data.data.goType == 1 ? "研学" : res.data.data.goType == 2 ? "竞赛" :res.data.data.goType == 3 ? "综合实践活动" :res.data.data.goType == 4 ? "其他" : "",
          status:
            res.data.data.goStatus == 1
              ? "已退回"
              : res.data.data.goStatus == 2
              ? "待审核"
              : res.data.data.goStatus == 3
              ? "待钉钉审批"
              : "已完成",
          outUnit: res.data.data.goUnit,
          outTime: res.data.data.goStartDate,
          leader: res.data.data.goResponsibleUser,
          job: res.data.data.goResponsiblePost,
          outStudents: res.data.data.goStuNum,
          outTeachers: res.data.data.goTeacherNum,
          outAddress: res.data.data.goEndArea,
          moneySource: res.data.data.goExpensesSource,
          outWay: res.data.data.goTransport,
          route: res.data.data.goRoute,
          outReason: res.data.data.goReason,
          picture: res.data.data.photoUrls,
          fileUrls: res.data.data.fileUrls,
          fileNames: res.data.data.fileNames,
          processList: res.data.data.processList,
          returnTime: res.data.data.goEndDate,
          outTeachersNames: res.data.data.goTeacherNames,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.printInfo {
  height: 100%;
  width: 100%;
}
.drawHead {
  padding: 0 10px 0 15px;
  width: 100%;
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
}
.drawHead_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawHead_number {
  color: rgba(124, 126, 128, 100);
  font-size: 12px;
}
.printBtn {
  display: flex;
  align-items: center;
  color: rgba(57, 133, 249, 100);
  cursor: pointer;
  .printImg {
    margin-right: 10px;
    font-size: 16px;
    color: #3985f9;
  }
}
.drawHead_title {
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
}
.drawHead_submitPerson {
  color: rgba(124, 126, 128, 100);
  font-size: 12px;
}
.drawFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
  box-shadow: 0px 0px 8px 0px rgba(198, 198, 198, 0.67);
  button {
    margin-left: 8px;
  }
}
.drawContent {
  padding: 10px;
  width: 100%;
  height: calc(100% - 165px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.drawContent1 {
  padding: 10px;
  width: 100%;
  height: calc(100% - 98px);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.drawContent_info {
  padding: 15px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
}
.drawContent_info_item {
  display: flex;
  align-items: center;
  .item_left {
    color: rgba(124, 126, 128, 100);
  }
  .item_right {
    color: rgba(16, 16, 16, 93);
  }
}
.marginBottom {
  margin-bottom: 10px;
}
.drawContent_item_title {
  color: rgba(124, 126, 128, 100);
}
.drawContent_item_text {
  color: rgba(16, 16, 16, 93);
}
.drawContent_item_pic {
  display: flex;
  align-items: center;
}
.drawContent_item_pic img {
  margin-right: 10px;
  width: 68px;
  height: 54px;
}
.drawContent_procedure {
  margin-top: 10px;
  padding: 12px 15px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
}
.drawContent_procedure_title {
  color: rgba(16, 16, 16, 100);
  font-size: 18px;
}
.stepDiv {
  margin-top: 10px;
}
.stepText {
  padding-left: 20px;
  width: 413px;
  display: flex;
  flex-direction: column;
  .stepTitle {
    width: 100%;
    display: flex;
    height: 19px;
    align-items: center;
    justify-content: space-between;
    .stepTitleLeft {
      font-size: 12px;
      color: #101010;
    }
    .stepTitleRight {
      font-size: 12px;
      color: #7c7e80;
    }
  }
  .stepName {
    font-size: 10px;
    color: #7c7e80;
    height: 69px;
  }
}
.stepIcon {
  width: 38px;
  height: 38px;
}
.drawContent_item_info {
  margin-top: 10px;
  .drawContent_item_info_item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .file_item_icon {
      color: #3985f9;
      font-size: 18px;
    }
  }
}
</style>
