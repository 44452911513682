<template>
  <div>
    <a-drawer
      :title="pdfTitle"
      placement="right"
      :closable="false"
      destroyOnClose
      :visible="visible"
      :wrapStyle="{ overflow: 'hidden' }"
      :bodyStyle="{ overflow: 'auto', height: 'calc(100% - 107px)' }"
      @close="onClose"
      :width="750"
    >
      <pdf
        ref="pdf"
        :page="pageNum"
        :src="url"
        @progress="loadedRatio = $event"
        @num-pages="pageTotalNum = $event"
      >
      </pdf>
      <div class="page">
        <div class="page-left">
          <a-button type="link" @click="prePage" size="small"
            ><a-icon type="left" />上一页</a-button
          >
        </div>
        <div class="pageNum">{{ pageNum }} / {{ pageTotalNum }}</div>
        <div class="page-right">
          <a-button type="link" @click="nextPage" size="small"
            >下一页<a-icon type="right"
          /></a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
/**
 *  显示pdf文件
 *
 *  import ShowPdf from '@/components/showPDF/showPdf.vue';
 *  <show-pdf :url='url'/>
 *
 *  @url  String  文件路径
 */
import pdf from "vue-pdf";
export default {
  name: "showPdf",
  data() {
    return {
      visible: false,
      loadedRatio: 0,
      pageNum: 1,
      pageTotalNum: 1,
    };
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    pdfTitle: {
      type: String,
      default: "world",
    },
  },
  components: {
    pdf,
  },
  methods: {
    showModal() {
      // this.getWordHtml();
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onSubmit() {
      this.$emit("fatherMethod");
      this.visible = false;
    },
    //上一页
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },
    // 下一页
    nextPage() {
      let page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    goFirst() {
      this.pageNum = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.pageNum {
  color: #409eff;
  // font-size:20px
}
</style>
