import { fetchEndpoint } from "@/server/network1";
import axios from "axios";
/**
 * fetchEndpoint
 *  @url    api 接口路径
 *  @data   接口传值
 *  @type   true json传参 /false form传参  ,默认json 可不填
 *  @fun    'GET'/'POST'  默认POST 可不填
 */

//后台登录
// export const login = (data) => fetchEndpoint("/school/pc/login", data);

//菜单
// export const getMenu = () =>
//   fetchEndpoint("/admin/Menu/getMenu", {
//     admin_roleid: JSON.parse(localStorage.getItem("loveUserInfos"))
//       .admin_roleid,
//   });

//获取七牛token
// export const getUploadToken = () =>
//   fetchEndpoint("/admin/UploadManager/getUploadToken", {});

//xxxxxxxxxxxxxxxxxx
// export const xxxxxxxxxxxxxxxxxx = (data) => fetchEndpoint(
//     "xxxxxxxxxxxxxxxxxx", data
// )

//列表
export const getPageList = (data) => fetchEndpoint(
    "/school/goApply/getPageList", { ...data }, true, 'POST'
)

//头部
export const statistics = (data) => fetchEndpoint(
    "/school/goApply/statistics", { ...data }, true, 'GET'
)
//获取学校
export const getCurrentUnit = (data) => fetchEndpoint(
    "/school/goApply/getCurrentUnit", { ...data }, true, 'POST'
)
//获取学校
export const getEntityById = (data) => fetchEndpoint(
    "/school/goApply/getEntityById", { ...data }, true, 'POST'
)

//提出申请
export const add = (data) => fetchEndpoint(
    "/school/goApply/add", { ...data }, true, 'POST'
)

//修改申请
export const edit = (data) => fetchEndpoint(
    "/school/goApply/edit", { ...data }, true, 'POST'
)

//修改申请
export const cancel = (data) => fetchEndpoint(
    "/school/goApply/cancel", { ...data }, true, 'POST'
)