<template>
  <div class="show_world">
    <a-drawer
      :title="worldTitle"
      placement="right"
      :closable="false"
      destroyOnClose
      :visible="visible"
      :wrapStyle="{ overflow: 'hidden' }"
      :bodyStyle="{ overflow: 'auto', height: 'calc(100% - 107px)' }"
      @close="onClose"
      :width="750"
    >
      <div v-html="vHtml" class="v_html" />
      <div class="drawer_bottom_btn">
        <a-button @click="onClose"> 关闭 </a-button>
        <a-button type="primary" @click="onSubmit"> 确定 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
/**
 * 引用import ShowWorld from "@/components/showWorld/showWorld"
 *
 * <ShowWorld ref="showWorld" :url='url' :worldTitle='worldTitle' @fatherMethod="fatherMethod"/>
 * 开启world抽屉  this.$refs.showWorld.showModal();
 *
 * 参数
 * @url         String world路径
 * @worldTitle  String  抽屉标题   默认:world
 * @fatherMethod  function  点击确定调用的方法
 */
import axios from "axios";
import mammoth from "mammoth";
export default {
  name: "showWorld",
  data() {
    return {
      visible: false,
      vHtml: "",
    };
  },
  props: {
    url: String,
    worldTitle: {
      type: String,
      default: "world",
    },
  },

  components: {},
  created() {},
  methods: {
    showModal() {
      this.getWordHtml();
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onSubmit() {
      this.$emit("fatherMethod");
      this.visible = false;
    },
    getWordHtml() {
      let that = this,
        url = that.url;
      axios({
        method: "get",
        url,
        async: true,
        responseType: "arraybuffer",
      })
        .then((res) => {
          that.formatWord(res);
        })
        .catch((res) => {
          that.formatWord(res);
        });
    },
    formatWord(data) {
      let that = this;
      mammoth
        .convertToHtml({
          arrayBuffer: new Uint8Array(data.data),
        })
        .then((res) => {
          that.$nextTick(() => {
            that.vHtml = res.value;
          });
        })
        .catch((res) => {
          this.openNotificationWithIcon("error", "word开启失败");
        });
    },
    openNotificationWithIcon(type, description) {
      this.$notification[type]({
        message: "系统提示",
        description,
      });
    },
  },
};
</script>
<style lang='less' scoped>
.v_html/deep/ img {
  max-width: 100%;
}
.drawer_bottom_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
  box-shadow: 0px 0px 8px 0px rgba(198, 198, 198, 0.67);
  button {
    margin-left: 8px;
  }
}
</style>